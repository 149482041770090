// src\components\Form\Select\Select.module.scss

@import '../../../styles/mdr';

.mdrSelect {
  // @include margin(0 0 15px);

  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    @include font-size(14px);
    @include padding(0 0 15px);

    align-items: flex-end;
    content: '▼';
    cursor: pointer;
    display: flex;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 25%;
    color: #d8d8d8;
    transform: scale(0.95, 0.55);
    transition: 0.3s color ease-in-out;
  }

  &:hover {
    &::after {
      color: $branding1;
    }
  }

  select {
    @include padding(0 10px);

    appearance: none;
    background: white;
    background-image: none;
    border: 1px solid #ced4da;
    border-radius: 0;
    min-height: 50px;
    color: black;
    cursor: pointer;
    flex: 1;
    outline: 0;

    &:hover {
      border-color: $branding1;
    }

    &:active,
    &:focus {
      border-radius: 0;
      // border-color: lighten($branding1, 0.5%);
      border-color: $branding-lighten-05;
      box-shadow: 0 0 0 0.2rem $branding1-1;
    }
  }
}
