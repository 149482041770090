// src\pages\profile\components\content\content.module.scss
@import '../../../../styles/mdr';

.profileContentContainer {
  .p0 {
    @include padding(0);
  }
}

.profileContentWrapper {
  // max-width: 822px;

  @include margin(80px auto 0);
  @include padding(0 15px);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  @include media-breakpoint-down(md) {
    @include padding(15px);
    @include margin(0 auto);
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  .leftContent {
    width: 176px;

    @include media-breakpoint-down(sm) {
      width: 100%;

      @include margin-bottom(15px);
    }

    .shareButton {
      margin-right: 10px;
    }
  }

  .rightContent {
    width: calc(100% - 176px);

    @include padding-left(56px);

    @include media-breakpoint-down(md) {
      @include padding-left(30px);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;

      @include padding-left(56px);
      @include padding-left(0);
    }

    .subHeading {
      font-family: $graphik;

      @include font-size(12px);

      font-weight: 300;
      line-height: 1.4;

      @include padding-bottom(8px);

      border-bottom: 1px solid $gray;

      @include margin-bottom(15px);
    }

    .innerHeading {
      font-family: $graphik;

      @include font-size(12px);

      font-weight: 300;
      line-height: 1.4;

      @include margin-bottom(8px);
    }

    .value {
      font-family: $opensans;
      line-height: 1.5;
      letter-spacing: -0.44px;
      color: $black;

      @include font-size(14px);

      @include margin-bottom(25px);
    }

    .notes {
      font-size: 12px;
    }

    .userServices {
      @include margin-bottom(35px);
      .serviceCard {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        border: 1px solid $gray;
        padding: 10px;
        align-items: center;
        font-weight: 600;
        .serviceTitle {
          max-width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .description {
      color: $gray;
      letter-spacing: 0.44px;

      @include margin-bottom(45px);

      pre {
        @include font-size(16px);
        overflow: unset;
        white-space: pre-wrap;
        font-family: $opensans;
        line-height: 1.5;
        letter-spacing: -0.44px;
        position: relative;
        color: $black;
      }
    }
  }

  .skill {
    @include padding(5px 15px);
    @include margin-right(5px);
    @include margin-top(5px);
    @include font-size(14px);

    display: inline-block;
    color: #8c8c8c;
    border: 1px solid lightgrey;
    border-radius: 50px;
  }
}

.skillsBox {
  @include margin-top(20px);

  padding-top: 14px;
  font-family: $graphik;

  .label {
    @include font-size(12px);

    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    color: $gray;
    line-height: 1;

    @include margin-bottom(15px);
  }
}

.box {
  @include margin-top(30px);

  border-top: 1px solid $light-gray;
  padding-top: 14px;
  font-family: $graphik;

  .label {
    @include font-size(12px);

    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    color: $gray;
    line-height: 1;

    @include margin-bottom(15px);
  }

  .duration {
    @include font-size(16px);

    color: $dark-gray;
  }

  .boxContent {
    @include font-size(14px);

    font-weight: 500;
    line-height: 1;
    word-break: break-all;

    ul {
      @include margin-top(25px);

      padding: 0;
      list-style: none;

      li {
        @include margin-bottom(15px);

        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        span {
          @include margin-right(8px);

          display: flex;
          background-color: $branding1;
          width: 18px;
          height: 18px;
          color: #fff;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.downloadResume {
  @include margin-bottom(44px);
  @include margin-top(30px);
  @include padding(0 5px);

  &.downloadCertificate {
    a {
      border: 1px solid $xlight-blue;
      color: $xlight-blue;
    }
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid $branding1;

    @include border-radius(4px);
    @include padding(18px 0);

    .pdfIcon {
      flex: 0 0 67px;
      max-width: 67px;

      @include font-size(30px);

      text-align: center;
      color: $medium-gray;
      line-height: 1;

      svg {
        height: 28px;
        width: auto;
      }
    }

    .download {
      margin-left: auto;

      @include font-size(12px);

      flex: 0 0 40px;
      line-height: 36px;
      max-width: 40px;
    }

    .fileName {
      @include font-size(17px);

      color: $medium-gray;
      font-family: $graphik;
      font-weight: 500;
      line-height: 1.1;

      @include margin-bottom(4px);
    }

    .fileExt {
      @include font-size(13px);

      color: $medium-gray;
      font-family: $graphik;
      font-weight: 300;
      line-height: 1.1;
    }
  }
}

.noCertificate {
  @include margin-bottom(40px);
  @include padding(20px 40px 32px);
  border: 1px solid lighten($medium-500, 55%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  .noCertBadge {
    @include margin-bottom(8px);
  }

  .noResumeHeading {
    @include font-size(18px);
    font-weight: 700;
  }

  .noResumeDate {
    @include font-size(14px);
    font-weight: 500;
    letter-spacing: -0.22px;
  }
}
