// src\components\Form\TextArea\TextArea.module.scss

@import '../../../styles/mdr';

textarea {
  &.mdrTextArea {
    margin: 0;
    min-height: 135px;
    border-radius: 0;
    color: $dark-gray;

    &:focus,
    &:active {
      // border-color: lighten($branding1, 0.5%);
      border-color: $branding-lighten-05;
      box-shadow: 0 0 0 0.2rem $branding1-1;
    }

    &::placeholder {
      color: $gray;
    }
  }
}
