// src\components\Button\Button.scss

@import '../../styles/mdr';

.micButton {
  &.micBtn {
    @include font-size(14px);
    @include padding(8px 16px);

    background-color: $branding1-1;
    border: 1px solid transparent;
    border-radius: 3rem;
    color: $dark-gray;
    font-family: $graphik;
    font-weight: 500;
    line-height: 1;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active:focus,
    &.btn-primary:not(:disabled):not(.disabled):active:focus {
      background-color: $branding1-1;
      border-color: transparent;
      color: $dark-gray;
      background-image: none;
      outline: 0;
      box-shadow: none;
    }

    &.transparent {
      background-color: transparent;

      &:hover {
        background-color: $branding1-1;
      }

      &.white {
        color: $white;

        &:hover {
          color: $dark-gray;
        }
      }
    }

    &.bold {
      @include font-size(18px);
      font-weight: 900;
    }

    &.outline {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;

      &:hover {
        background-color: $white;
        color: $dark-gray;
      }

      &.active {
        background-color: transparent;
        border-color: $light-gray;
        color: $light-gray;
      }
    }

    &.outline-gray {
      border: 1px solid #c4c4c4;
      background-color: transparent;
      color: #5a5a5a;

      &:hover {
        background-color: #cecece;
        color: $white;
      }
    }

    &.fadeBtn {
      border: 1px solid #c4c4c4;
      background-color: transparent;
      color: #c4c4c4;

      &:hover {
        background-color: #cecece;
        color: $white;
      }
    }

    &.gradient-blue {
      background-size: 200% auto;
      transition: 0.5s;
      color: $white;
      background-image: linear-gradient(to right, #0897b2 0%, #1ad3f5 50%, #16d9ea 100%);

      &:hover {
        background-position: right center;
      }
    }

    &.blue {
      background-color: $light-blue;
      color: $blue;

      &:hover {
        background-color: darken($light-blue, 5%);
      }
    }

    &.gray {
      background-color: #cecece;
      color: $white;

      &:hover {
        background-color: darken(#cecece, 5%);
      }
    }

    &.black {
      background-color:$black;
      color: $white;

      &:hover {
        background-color: darken($gray, 5%);
      }
    }

    &.light-pink {
      background-color: $light-pink;
      color: $black;

      &:hover {
        background-color: darken($light-pink, 5%);
      }
    }

    &.light-green {
      background-color: $light-green;
      border-color: $light-green;
      color: $black;

      &:hover {
        background-color: darken($light-green, 5%);
      }
    }

    &.noRadius {
      border-radius: 0;
    }

    &.large {
      @include padding(15px 50px);
      @include font-size(18px);

      font-family: $graphik;
      font-weight: bold;
      background-color: transparent;
      border: 1px solid #c4c4c4;
      border-radius: 3rem;
      color: #5a5a5a;
      transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;

      &:hover {
        background-color: $branding1-1;
        color: $dark-gray;
      }
    }

    &.lgBtn {
      @include padding(15px 30px);
      @include font-size(16px);
      font-weight: 600;
    }

    &.smBtn {
      @include padding(10px 30px);
      @include font-size(14px);
      font-weight: 500;
    }

    &.branding {
      background-color: $branding1;
      color: $white;

      &:hover {
        background-color: $branding-darken-10;
        color: $white;
      }
    }

    // &.green {
    //   background-color: $branding1;
    //   color: $white;

    //   &:hover {
    //     background-color: $branding-darken-10;
    //     color: $white;
    //   }
    // }
    &.green-check {
      background-color: $branding1;
      color: $white;
      background-image: url('../../assets/svgs/check.svg');
      background-position: 14px;
      background-repeat: no-repeat;
      padding-left: 35px;
      &:hover {
        background-color: $branding-darken-10;
        color: $white;
      }
    }

    &.black-lock {
      background-color: $branding1;
      color: $white;
      background-image: url('../../assets/svgs/check.svg');
      background-position: 14px;
      background-repeat: no-repeat;
      padding-left: 35px;
      &:hover {
        background-color: $branding-darken-10;
        color: $white;
      }
    }

    &.lt-gray {
      background-color: $xlight-gray;
      color: $gray;
    }

    &.sm-font {
      @include font-size(12px);
      letter-spacing: 1.5px;
      font-weight: 500;
    }
    &.md-font {
      @include font-size(14px);
      letter-spacing: 1.5px;
      font-weight: 600;
      @include padding-left(10px);
      @include padding-right(10px);
      letter-spacing: 0;
    }

    &.add-btn {
      @include font-size(12px);
      font-weight: 500;
      color: $branding1;
      position: relative;
      background-image: url('../../assets/imgs/add-btn@2x.png');
      background-size: 21px auto;
      background-position: 12px 3
      px;
      background-repeat: no-repeat;
      padding-left: 40px;
      border-color: transparent;
      &:hover {
        background-color: transparent;
        color: $branding-darken-10;
      }
    }

    &.icon-only {
      padding: 0;
      width: 24px;
      height: 24px;
      background-position: 0 0;
    }

    &.wd-200 {
      width: 200px;
    }
  }
}
