// src/pages/Login/Login.module.scss

@import '../../styles/mdr';

.authPage {
  position: relative;

  .authPageWrap {
    @include padding(100px 0 );

    min-height: 500px;

    .authForm {
      .authFormWrap {
        @include media-breakpoint-down(md) {
          max-width: 540px;
          margin: 0 auto;
        }

        .authFormTitle {
          @include font-size(28px);
          @include margin-bottom(25px);

          font-family: $graphik;
          font-weight: bold;
          line-height: 1.21;
          letter-spacing: -0.78px;
          color: #0f0f0f;
        }

        .authLabel {
          @include font-size(16px);
          @include margin-bottom(5px);

          font-family: $graphik;
          font-weight: bold;
          letter-spacing: -0.44px;
          color: $black;
        }

        .authInput {
          @include margin-bottom(25px);
        }

        .authButton {
          @include margin(15px 0);
        }

        .authSubText {
          @include padding(15px 0);
          @include font-size(16px);

          color: #5a5a5a;
          text-align: center;

          a {
            font-family: $graphik;
            font-weight: bold;
            text-decoration: underline;

            &:visited {
              @extend a;
            }
          }
        }
      }

      .success {
        color: green;
        margin-top: 8px;
      }

      .changed {
        display: block;
      }

      .error {
        color: $red;
        margin-top: 8px;
      }
    }
  }
}
