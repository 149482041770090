@import '../../styles/mdr';

.privacyContainer {
  color: gray;

  .pageHeader {
    @include font-size(1.75rem);
    @include margin(25px);

    font-weight: bolder;
    text-align: center;
  }

  p {
    @include font-size(15px);
  }

  ul {
    margin-bottom: 0;
    font-size: medium;
  }
}
