@import '../../../styles/mdr';

.autoCompleteWrap {
  width: 100%;
  position: relative;
  &::after {
    font-size: 0.875rem;
    padding: 0 0 0.9375rem;
    align-items: flex-end;
    content: '▼';
    cursor: pointer;
    display: flex;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 25%;
    color: #d8d8d8;
    transform: scale(0.95, 0.55);
    transition: 0.3s color ease-in-out;
  }
  &:hover {
    &::after {
      color: $branding1;
    }
  }
}

.autoCompleteInput {
  padding: 0 0.625rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  background-image: none;
  border: 1px solid #ced4da;
  border-radius: 0;
  min-height: 50px;
  color: black;
  cursor: pointer;
  flex: 1 1;
  outline: 0;
  width: 100%;
  &:hover {
    border-color: $branding1;
  }

  &:active,
  &:focus {
    border-radius: 0;
    // border-color: lighten($branding1, 0.5%);
    border-color: $branding-lighten-05;
  }
}

:global {
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
}