// src\pages\Register\Continued\Continued.module.scss

@import '../../../styles/mdr';

.registerContinued {
  .registerTitle {
    @include font-size(44px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -1.22px;
    color: $dark-gray;
  }

  :global {
    .btn-grey-ghost {
      border-radius: 3rem;
      font-family: $graphik;
      font-weight: 500;
      line-height: 1;
      border: 1px solid #c4c4c4;
      background-color: transparent;
      color: #5a5a5a !important;
      text-align: center;
      width: 100%;
      display: block;
      @include padding(15px 50px);
      font-weight: 600;

      &:hover {
        background-color: #cecece;
        color: $white;
      }
    }
  }

  .registerSubTitle {
    @include font-size(16px);

    letter-spacing: -0.44px;
    color: #5a5a5a;
  }

  .requiredFieldsNote {
    @include font-size(12px);

    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;

    span {
      color: $red;
    }
  }

  .registerIntro {
    @include font-size(28px);

    font-family: $graphik;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: -0.78px;
    color: #0f0f0f;
  }

  .registerLabelSection {
    @include padding-top(15px);
    @include margin-top(15px);

    position: relative;

    .registerLabel {
      @include font-size(16.8px);

      font-family: $graphik;
      font-weight: 500;
      line-height: 1.24;
      color: #323232;
    }

    .registerSubLabel {
      @include font-size(12.8px);

      font-family: $graphik;
      font-weight: 300;
      color: #323232;
    }

    &::before {
      @include media-breakpoint-up(md) {
        width: 80%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      display: block;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      height: 1px;
      background-color: #979797;
    }
  }

  .authLabel {
    @include font-size(16px);
    @include margin-bottom(5px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.44px;
    color: $black;
  }

  .authInput {
    @include margin-bottom(30px);

    position: relative;
    :global {
      .chip {
        white-space: normal;
      }
    }

    .errorInput {
      border-color: $red;
    }
  }

  .authButton {
    @include margin(15px 0);
    @include padding-top(15px);

    .loginNote {
      font-family: $graphik;
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .requiredField,
  .error {
    color: $red;
  }
}

.infoHover {
  display: inline-block;

  @include border-radius(50%);

  background: $gray;
  color: $white;
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-weight: 500;

  @include margin-left(5px);
  @include font-size(12px);

  text-align: center;
}

.shortMess {
  display: inline-block;

  @include margin-left(5px);

  color: $branding1;

  @include font-size(14px);
}

.signUpTerms {
  @include padding(20px 0);

  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
