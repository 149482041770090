// src\styles\abstracts\_variables.scss

:root {
    --branding1: #ef5139;
    --branding1_1: #ffeeec;
    --branding1_light: #2a323d;
    --branding2: #ef5139;
    --branding3: #9143fe;
    --textColor: #1c1c1c;
    --footerColor: #2a323d;
    --headingColor: #1c1c1c;
    --imageBackgroundColor: #ef5139;
    --socialBackgroundColor: #ef5139;
    --footerLogoWidth: 60px;
    --headerLogoWidth: 200px;
    --inputActiveBorderColor: #ef533b;
    --buttonHoverColor: #e32e12;
    --messageBackgroundColor: #fffcfb;
}

$branding1: var(--branding1) !default;
$branding1-1: var(--branding1_1) !default;
$branding1-light: var(--branding1_light) !default;
$branding2: var(--branding2) !default;
$branding3: var(--branding3) !default;
$branding-lighten-05: var(--inputActiveBorderColor) !default;
$branding-darken-10: var(--buttonHoverColor) !default;
$branding-1_1_light: var(--messageBackgroundColor) !default;

$dark-gray : var(--textColor) !default;

$footer-color: var(--footerColor) !default;
$heading-color: var(--headingColor) !default;
$image-background-color: var(--imageBackgroundColor) !default;
$social-background-color: var(--socialBackgroundColor) !default;
$footer-logo-width: var(--footerLogoWidth) !default;
$header-logo-width: var(--headerLogoWidth) !default;


$branding2-light: #9143fe !default;
$branding2-1: #f6f0ff !default;
$branding3-1: #fffddd !default;

$white: #fff !default;
$black: #000 !default;
$light-gray: #d7d7d7 !default;
$xlight-gray: #f1f1f1 !default;
$gray: #909090 !default;
$light-pink: #ffe6e6 !default;
$medium-gray: #323232 !default;
$medium-500: #5a5a5a !default;
$red: #d93025 !default;
$light-blue: #e9f5ff !default;
$blue: #097ad0 !default;
$xlight-blue: #09B1D0 !default;
$error-box: #ffeeee !default;
$error: #ff7b7b !default;
$xxlight-blue: #f8fffc !default;
$light-green: #ddffef !default;
$xlight-green: #c6fce4 !default;
$blue-100: #fafdff !default;
$blue-600: #89ccff !default;
$dark-yellow: #9c9860 !default;
$gray-c: #ced4da !default;


$graphik: 'Graphik',
sans-serif;
$opensans: 'Open Sans',
sans-serif;