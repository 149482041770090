// src\pages\JobBoard\Result\Result.module.scss

@import '../../../styles/mdr';

.jobResult {
  @include padding-top(25px);
  @include padding-bottom(25px);

  border-bottom: 1px solid #d1d1d1;

  /* &:first-child {
    padding-top: 0;
  } */

  .resultDataRow {

    .resultImageCol {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        @include margin-bottom(15px);
        // padding: 0;
      }

      @include media-breakpoint-down(xs) {
        align-items: center;
      }

      img, svg {
        border-radius: 0.3rem;
        height: 70px;
        width: auto;
        max-width: 70px;
      }
    }

    .resultInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        // padding: 0;
      }

      @include media-breakpoint-down(xs) {
        align-items: center;
      }

      .featuredJob {
        @include font-size(11px);

        letter-spacing: 1px;
        color: #9958ea;
        font-weight: 700;
        text-transform: uppercase;
      }

      .resultTitle {
        @include font-size(20px);
        @include padding(0 0 5px 0);

        font-family: $graphik;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: -0.56px;
        color: $black;
        @include media-breakpoint-down(xs) {
          text-align: center;
        }
      }

      .resultAttributes {
        @include font-size(14px);

        font-family: $graphik;
        font-weight: 500;
        letter-spacing: -0.39px;
        color: #5a5a5a;
        text-transform: capitalize;

        .resultLocation {
          @include margin-left(15px);
        }

        .resultInvite {
          @include margin-right(15px);
        }

        @include media-breakpoint-down(sm) {
          span {
            display: block;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .resultLocation {
            @include margin-left(0);
            @include margin-top(5px);
          }
          .resultInvite {
            @include margin-right(0);
            @include margin-top(5px);
          }
        }
      }
    }

    .resultAvailability {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-left: auto;

      @include media-breakpoint-down(xs) {
        @include margin-top(15px);
      }

      .resultType {
        @include font-size(14px);
        @include padding(5px 25px);

        align-items: center;
        color: #5a5a5a;
        display: inline-flex;
        font-family: $graphik;
        font-weight: 500;
        justify-content: center;
        position: relative;
        text-align: center;
        text-transform: capitalize;

        @include media-breakpoint-down(xs) {
          @include padding(5px 10px);
        }

        &::after {
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%;
          box-sizing: border-box;
          content: '';
          display: block;
          border-top: 1px dashed #d1d1d1;
        }

        &.fullTime {
          background-color: $branding1-1;
        }

        &.partTime {
          background-color: $branding3-1;
        }

        &.oneOff {
          background-color: $branding2-1;
        }
      }

      .resultTypeDate {
        @include font-size(12px);
        @include padding-top(15px);

        color: #9d9d9d;
        font-family: $graphik;
        font-weight: 500;
        letter-spacing: -0.33px;
        position: relative;
        @include media-breakpoint-down(xs) {
          @include padding(15px 0 0);
        }
      }
    }
  }
}

.featured {
  background-color: #fbf8ff;
}

.resultOptions {
  @include padding-top(25px);
  @include padding-bottom(25px);

  button + button {
    @include margin-left(15px);
  }

  .optionsRight {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include padding(0);

    @include media-breakpoint-down(sm) {
      justify-content: flex-start;

      @include margin-top(15px);
    }

    .availabilityText {
      @include font-size(12px);
      @include margin-right(15px);
      @include margin-left(15px);

      font-family: $graphik;
      font-weight: 300;
    }
  }
}
