// src\pages\Careers\components\Header\Header.module.scss

@import '../../../../styles/mdr';

.pageHeader {
  @include margin(15px auto);
  @include padding(15px 0 0 0);

  .pageTitle {
    @include font-size(32px);

    @include media-breakpoint-up(mg) {
      text-align: left;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    color: $heading-color;
    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.89px;
  }

  .pageSubtitle {
    @include font-size(16px);

    @include media-breakpoint-up(mg) {
      text-align: right;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    color: #5a5a5a;
    letter-spacing: -0.44px;
  }
}
