@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;
  text-transform: capitalize;

  @include margin-bottom(30px);
}

.invites {
  min-height: 120px;
}

.noJobsFound {
  padding: 20px 35%;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}

.error {
  color: $red;
  margin: auto;
}

.talentList {
  font-family: $graphik;
  display: flex;
  align-items: center;
  @include padding(12px 35px);
  border-radius: 5px;
  border: solid 1px $light-gray;
  @include margin-bottom(20px);

  @include media-breakpoint-down(md) {
    @include padding(12px 15px);
  }

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  .talentImg {
    width: 80px;

    @include media-breakpoint-down(xs) {
      width: 100%;
      text-align: center;
    }

    img {
      border-radius: 50%;
      width: 80px;
      height: auto;
    }
  }

  .talentInfo {
    width: calc(100% - 250px);
    @include padding-left(25px);

    @include media-breakpoint-down(xs) {
      width: 100%;
      @include padding-left(0);
      @include margin-top(10px);
      text-align: center;
    }

    .talentTitle {
      @include font-size(20px);
      font-weight: bold;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(xs) {
        justify-content: center
      }
      span {
        @include margin-right(10px);
      }
    }

    .talentSkills {
      @include font-size(12px);
      font-weight: 500;
      line-height: 20px;
      color: $medium-500;
    }
  }


  .talentAvailability {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-down(xs) {
      align-items: center;
    }

    .payRange {
      font-weight: 500;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      text-align: center;
      border-top: 1px solid $xlight-gray;
      @include margin-top(10px);
    }
    > button,
    >div {
      @include margin(5px 0);
    }
  }
}
