@import 'styles/mdr';

.error {
  color: red;
}

.inviteUserModal {
  .notice {
    font-size: 14px;
    margin-top: 5px;
  }
  .authLabel {
    @include font-size(16px);

    letter-spacing: -0.44px;
    margin-bottom: 14px;

    span {
      @include font-size(12px);
    }
  }

  .modalNoti {
    display: flex;
    @include margin-bottom(25px);

    span:first-child {
      @include margin-right(15px);
    }

    span {
      @include font-size(12px);
      font-weight: 18px;
    }
  }

  .authInput {
    @include margin-bottom(25px);

    position: relative;

    textarea {
      resize: none;
      height: 140px;
    }
  }
  .closeBtn {
    @include font-size(12px);
    cursor: pointer;
    font-weight: 500;
    color: darken($light-gray, 15%);
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    align-items: center;

    span {
      @include margin-left(2px);
    }
  }
  .userInfo {
    display: flex;
    align-items: center;
    @include margin-bottom(40px);

    .userDetail {
      width: calc(100% - 58px);
      @include padding-left(15px);

      .userName {
        font-weight: bold;
        line-height: 1;
      }

      .userAdd {
        @include font-size(12px);
      }
    }
    .imgSec {
      width: 58px;
      height: 58px;

      img {
        border-radius: 50%;
        max-width: 100%;
      }
    }
  }
  :global {
    .modal-content {
      @include padding(40px 60px 40px);
      font-family: $graphik;
      position: relative;

      @include media-breakpoint-down(sm) {
         @include padding(40px 25px 40px);
      }
    }
    .modal-header {
      border: none;
      padding: 0;

      .h4 {
        @include font-size(24px);
        font-weight: bold;
        letter-spacing: -.67px;
        @include margin-bottom(30px);
      }
    }

    .modal-footer {
      padding: 0;
      border: none;
    }

    .modal-body {
      padding: 0;

    }
  }
}