@import '../../styles/mdr';

.verifyPage {
  .verifyWrapper {
    min-height: 380px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .imgWrapper {
    @include margin-bottom(10px);
  }

  .heading {
    @include font-size(36px);
    font-weight: 700;
    font-family: $graphik;
    @include margin-bottom(8px);
  }

  .subHeading {
    @include font-size(20px);
  }
}