@import '../../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.messageListing {
  border: 1px solid $light-gray;

  @include padding(15px 30px);

  position: relative;

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  nav {
    border: none;

    @include margin-bottom(36px);

    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      border: none;
      font-weight: 500;
      color: $gray;

      @include font-size(16px);

      line-height: 1;
    }
  }

  .conversationsList {
    position: relative;
    min-height: 120px;

    .noMessagesFound {
      padding: 20px 35%;
      border: 1px solid lightgrey;
      text-transform: uppercase;
    }
  }
}

.searchBox {
  position: absolute;
  max-width: 300px;
  width: 100%;
  top: 15px;
  right: 22px;

  @include media-breakpoint-down(sm) {
    position: relative;
    top: 0;
    right: 0;

    @include margin-bottom(15px);
  }

  .mdrInputSmall {
    min-height: 44px;

    @include padding-right(45px);
  }

  .searchIcon {
    position: absolute;
    right: 15px;
    top: 15px;

    @include font-size(16px);
  }
}

.messageList {
  .list {
    border-bottom: 1px solid $light-gray;

    @include padding-bottom(32px);
    @include margin-bottom(20px);

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &:last-child {
      border-bottom: none;
    }

    .img {
      width: 55px;

      img {
        max-width: 40px;
        height: auto;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }

    .messageInfo {
      flex: 0 0 calc(100% - 55px);
      max-width: calc(100% - 55px);
      font-family: $graphik;

      .user {
        @include font-size(12px);

        line-height: 16px;
        letter-spacing: -0.19px;
        color: $branding1;
        font-weight: 500;
      }

      .time {
        @include font-size(12px);

        line-height: 16px;
        letter-spacing: -0.19px;
        color: $gray;
        font-weight: 500;
      }

      .MessageHeading {
        @include font-size(20px);

        line-height: 26px;
        letter-spacing: -0.56px;
        color: $black;
        font-weight: 500;

        @include margin-bottom(15px);

        a {
          color: $black;

          &:hover {
            color: $branding1;
          }
        }
      }
    }
  }
}
