@import 'styles/mdr';

.sectionHeading {
    @include font-size(24px);
    line-height: 34px;
    letter-spacing: 0.67px;
    font-weight: 300;
    color: $black;
    @include margin-bottom(30px);

    .messageUser {
      font-weight: 700;
    }
}

.editorWrapper {
  border: 1px solid #f1f1f1;
}

.toolbar {
  border: none;
  border-bottom: 1px solid #f1f1f1;
}

.textEditor {
  padding: 0 10px 10px 10px;
}

.downloadResume {
  margin-bottom: 15px;
  a {
    border: 1px solid $branding1;
    display: flex;
    max-width: 420px;
    border-radius: 5px;
    @include padding(18px 20px);
    align-items: center;

    .fileType {
      line-height: 1.2;
      .fileName {
        font-weight: 500;
        @include font-size(18px);
        color: $black;
        @include margin-bottom(2px);
      }

      .fileExt {
        @include font-size(14px);
        color: $medium-500;
      }
    }

    .download {
      margin-left: auto;
    }

    .pdfIcon {
      @include margin-right(20px);

      svg {
        width: 30px;
        height: auto;
      }
    }
  }
}

.sidebarPanel {
  border-top: 1px solid $gray-c;
  @include padding-top(10px);
  @include margin-bottom(30px);

  .heading {
    @include font-size(12px);
    text-transform: uppercase;
    color: $gray;
    letter-spacing: 2px;
    font-weight: 500;
    @include margin-bottom(10px);
  }
  .subHeading {
    @include font-size(16px);
    font-weight: 500;
  }
}

.applicantDetail {

  .fileUploadWrap {
    display: flex;
    .fileInfo {
      width: 120px;
      font-style: italic;
      font-family: $opensans;
      line-height: 1.8;
      letter-spacing: -0.33px;
      @include font-size(12px);
      color: $gray;
    }
    .fileNote {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .attachmentsListing {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    .uploadBtn {
      margin-left: 20px;
    }
    .attachmentRemove {
      cursor: pointer;
      margin-left: 20px;
      color: $red;
    }
    .attachmentIcon {
      margin-right: 10px;
    }
  }

  @include media-breakpoint-down(sm) {
    @include margin( 0 0 25px);
  }

  .schBreakdown {
    @include margin-bottom(25px);

    .heading {

      @include font-size(16px);
      font-weight: 500;
      @include margin-bottom(20px);
    }

    .schListing {
      @include padding-bottom(30px);
      @include padding-left(40px);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 12px;
        top: 12px;
        background: $gray-c;
        height: 100%;
        width: 1px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 5px;
        background: $white;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        border: 1px solid $gray-c;
      }

      .schTiming {
        @include font-size(16px);
        font-weight: 500;
        @include margin-bottom(2px);
      }

      .schDesc {
        @include font-size(14px);
      }

      &:last-child {
        @include padding-bottom(0);

        &:before {
          display: none;
        }
      }
    }
  }

  h3 {
    @include font-size(20px);
    @include margin-bottom(15px);
  }

  .userName {
    @include font-size(16px);
    font-weight: 500;
    color: $branding1;
    @include margin-bottom(5px);
  }

  .appTop {
    @include margin-bottom(15px);
  }

  .createdApp {
    @include font-size(12px);
    font-weight: 500;
    color: $gray;
  }

  .descSec {
    @include margin-bottom(25px);

    .descSecTop {
      @include font-size(16px);
      font-weight: 500;
      @include margin-bottom(10px);
    }

    .descSecBottom {
      color: $gray;
    }
  }
}
.paymentPopup {
  .closeBtn {
    @include font-size(12px);
    cursor: pointer;
    font-weight: 500;
    color: darken($light-gray, 15%);
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    align-items: center;

    span {
      @include margin-left(2px);
    }
  }

  .payFormHeading {
      display: flex;
      @include padding-top(20px);
      align-items: center;
      justify-content: space-between;
      @include font-size(18px);
      font-weight: bold;
      letter-spacing: -.67px;
      @include margin-bottom(20px);
  }

  .paySum {
    .payForm {
      @include margin-top(30px);
      @include padding-top(20px);
      border-top: 1px solid darken($light-gray, 15%);
    }
    .payRow {
      @include margin-bottom(20px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include font-size(14px);

      &.paySubtotal {
        @include font-size(16px);
        font-weight: bold;
      }
    }
  }
  :global {

    .new-card-form {
      > div {
        margin-left: 0 !important;
      }
    }
    .checkout-form {
      > div {
        padding: 0;
      }
      .new-card-form {
        margin: 0;

        .col-lg-8,
        .col-xl-8 {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
          margin: 10px 0;
        }

        .col-lg-4,
        .col-xl-4{
          text-align: right;
          flex: 0 0 100%;
          max-width: 100%;
          margin: 10px 0;
        }
      }
    }

    .modal-content {
      @include padding(50px 60px 40px);
      font-family: $graphik;
      position: relative;

      @include media-breakpoint-down(sm) {
         @include padding(40px 25px 40px);
      }
    }
    .modal-header {
      border: none;
      padding: 0;

      .h4 {
        @include font-size(18px);
        font-weight: bold;
        letter-spacing: -.67px;
        @include margin-bottom(30px);
      }
    }

    .modal-footer {
      padding: 0;
      border: none;
    }

    .modal-body {
      padding: 0;

    }
  }
}


.messageListing {
  border: 1px solid $light-gray;
  @include padding(20px 30px);

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  .payRedirect {
    .imgWrapper {
      position: static;
      @include margin(0 0 40px);
    }
  }

  .imgWrapper {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -50px;

    @include media-breakpoint-down(sm) {
      position: static;
      @include margin(30px 0 0);
      order: 3;
    }

    img {
      height: 100px;
      border-radius: 50%;
      width: 100px;
    }
  }

  .messageDetailsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include margin-bottom(50px);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    a {
      @include font-size(14px);
      font-weight: 500;
      color: $gray;

      @include media-breakpoint-down(sm) {
        @include margin-bottom(15px);
      }

      &:hover {
        color: $branding1;
      }

      svg {
        @include margin-right(10px);
      }
    }

    button {
      @include font-size(12px);
    }
  }
  nav {
    border: none;
    @include margin-bottom(36px);

    a {
      border: none;
      padding: 0 5px;
      font-weight: 500;
      color: $gray;
      @include font-size(16px);
      line-height: 1;
    }
  }

  .chatBox {
    .list{
      @include margin-bottom(20px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      &.listClient {
          justify-content: flex-start;

          .img {
            order: 1;
            text-align: left;
          }
          .messageInfo {
            order: 2;
            border: 1px solid $branding1;
            background: $branding-1_1_light;
          }
      }

      .img {
          width: 65px;
          order: 2;
          text-align: right;

          img {
            max-width: 50px;
            height: auto;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
      }
      .messageInfo {
        flex: 0 0 calc(100% - 130px);
        max-width: calc(100% - 130px);
        font-family: $graphik;
        @include padding(20px);
        border: 1px solid $light-gray;

        @include media-breakpoint-down(sm) {
          @include padding(10px);
          flex: 0 0 calc(100% - 65px);
          max-width: calc(100% - 65px);
        }

        .user {
          @include font-size(12px);
          line-height: 16px;
          letter-spacing: -0.19px;
          color: $branding1;
          font-weight: 500;
        }

        .time {
          @include font-size(12px);
          line-height: 16px;
          letter-spacing: -0.19px;
          color: $gray;
          font-weight: 500;
        }

        .MessageHeading {
          @include font-size(16px);
          line-height: 26px;
          letter-spacing: -0.56px;
          color: $black;
          @include margin-bottom(15px);

          a {
            color: $black;
          }
        }
      }
    }
  }
}

.textAreaOuter {
  @include padding(0 65px);
  @include margin-bottom(20px);

  @include media-breakpoint-down(sm) {
    @include padding(0);
  }

  textarea {
    resize: none;
  }

  .smallInfo {
    @include font-size(12px);
    @include margin-top(10px);
  }
}

.counterOfferInput {
  @include padding(0 65px);
  @include margin-bottom(30px);
  @include margin-top(30px);

  @include media-breakpoint-down(sm) {
    @include padding(0);
  }

  .checkRow {
    display: flex;
    align-items: center;

    > input {
      width: 150px;
      @include margin-left(40px);
      background: $white;
    }
  }
}

.btnOuter {
  @include padding(0 65px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(sm) {
    @include padding(0);

    flex-direction: column;

    button:first-child {
      margin-bottom: 15px;
    }
  }
}

.messageList {

  .list{
    @include margin-bottom(55px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .img {
        width: 65px;
        order: 2;
        text-align: right;

        img {
          max-width: 50px;
          height: auto;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
    }
    .messageInfo {
      order: 1;
      flex: 0 0 calc(100% - 65px);
      max-width: calc(100% - 65px);
      font-family: $graphik;

      .user {
        @include font-size(12px);
        line-height: 16px;
        letter-spacing: -0.19px;
        color: $branding1;
        font-weight: 500;
      }

      .time {
        @include font-size(12px);
        line-height: 16px;
        letter-spacing: -0.19px;
        color: $gray;
        font-weight: 500;
      }

      .MessageHeading {
        @include font-size(20px);
        line-height: 26px;
        letter-spacing: -0.56px;
        color: $black;
        font-weight: 500;
        @include margin-bottom(15px);

        a {
          color: $black;
        }
      }
    }
  }
}

.payRedirect {
  text-align: center;
  @include padding(0 120px 60px);

  @include media-breakpoint-down(sm) {
    @include padding(0 15px);
  }

  .heading {
    font-weight: 500;
    @include font-size(22px);
    letter-spacing: -.34px;
    margin-bottom: 20px;
    line-height: 1;
  }

  .desc {
    @include font-size(20px);
    letter-spacing: -.56px;
    margin-top: 20px;
    line-height: 26px;
    @include margin-bottom(30px);
  }

  .payInfo {
    @include font-size(12px);
    letter-spacing: -.33px;
    line-height: 18px;
    @include margin-top(30px);
    font-weight: 300;
  }
}