// src\pages\JobBoard\Post\components\Types\Career.module.scss

@import '../../../../../styles/mdr';

.careerType {
  .formDivider {
    @include margin(25px 0);

    border-top: 1px dashed #d1d1d1;
    height: 1px;
    width: 100%;
    max-width: 100%;
  }

  .featureDesc {
    @include font-size(14px);

    @include media-breakpoint-up(lg) {
      @include padding-right(50px);
    }

    font-family: $opensans;
    line-height: 1.57;
    letter-spacing: -0.39px;
    color: #909090;
  }

  .featureList {
    ul {
      text-align: left;
      margin: 0;
      padding: 0;

      li {
        @include padding-left(15px);
        @include font-size(12px);

        @include media-breakpoint-up(md) {
          @include margin-right(10px);

          display: inline-block;
        }

        font-family: $opensans;
        font-weight: 600;
        position: relative;
        line-height: 1.83;
        letter-spacing: -0.33px;
        color: #414141;
        list-style: none;

        &::before {
          position: absolute;
          left: 0;
          content: '✔';
          color: $branding1;
        }
      }
    }
  }

  .limitedPriceData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-down(md) {
        @include margin-top(15px);
    }

    .limitedPricing {
      @include font-size(12px);

      font-family: $opensans;
      font-weight: 600;
      letter-spacing: -0.33px;
      color: #414141;
    }

    .limitedPrice {
      @include font-size(22px);

      font-family: $opensans;
      font-weight: bold;
      letter-spacing: -0.61px;

      s {
        color: #9f9f9f;
      }

      b {
        color: #414141;
      }
    }

    .limitedButtons {
      a {
        min-width: 237px;
        height: 35px;
      }

      button {
        min-width: 180px;
      }
    }
  }

  .credName {
    margin-top: 10px;
  }

  .inputLabel {
    @include font-size(16px);
    @include margin(0 0 15px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.44px;
    color: $black;
    position: relative;

    @include media-breakpoint-down(md) {
      &.inputQuestion {
        @include margin-top(15px);
        text-align: center;
      }
    }

    span {
      font-weight: normal;
    }

    span.requiredField {
      color: $red;
      font-weight: bold !important;
    }

    .requiredFieldsNote {
      @include font-size(12px);

      position: absolute;
      right: 0;
      top: 20px;
      margin-bottom: 5px;
      font-weight: 700;

      @include media-breakpoint-down(sm) {

        top: -30px;
      }

      span {
        color: $red;
      }
    }
  }

  .checkRow {
    display: flex;
    flex-wrap: wrap;


    > div {
      margin-right: 10px;

      @include media-breakpoint-down(sm) {

        margin-bottom: 10px;
      }
    }
  }

  .subText {
    @include font-size(12px);
    @include margin(30px 0 0);

    font-family: $opensans;
    font-style: italic;
    line-height: 1.83;
    letter-spacing: -0.33px;
    color: #909090;
  }

  .jobSubmitError {
    margin-top: 25px;
    margin-bottom: -40px;

    .apiError {
      color: $error;
      background-color: $error-box;
      font-size: 12px;
      padding: 11px;
      text-align: center;
    }
  }

  .jobSubmitSuccess {
    .apiSuccess {
      color: $branding1;
      font-size: 12px;
      padding: 11px;
      text-align: center;
    }

    margin-top: 20px;
  }

  .posRel {
    position: relative;
  }

  .applyCouponBtn {
    right: 25px;
    top: 9px;
    position: absolute;
    z-index: 9999;
  }

  .error {
    color: $red;

    @include font-size(14px);
  }
}

.infoHover {
  display: inline-block;

  @include border-radius(50%);

  background: $gray;
  color: $white;
  height: 16px;
  width: 16px;
  line-height: 21px;
  font-weight: 500;

  @include margin-left(5px);
  @include font-size(12px);

  text-align: center;
}

.inviteBanner {
  margin-bottom: -40px;
}

.shortDesc {
  @include font-size(14px);
}

.authInput {
  @include margin-bottom(25px);
}

.customInputOuter {
  :global {

    input{
      margin: 0;
      min-height: 50px;
      border-radius: 0;
      color: $dark-gray;
      border: 1px solid $gray-c;
      @include padding(0 12px);
      color: $gray;
      outline: 0;
      width: 100%;

      &:hover {
        outline: 0;
        border-color: $branding1;
      }

      &:focus,
      &:active {

      outline: 0;
        // border-color: lighten($branding1, 0.5%);
        border-color: $branding-lighten-05;
        box-shadow: 0 0 0 0 transparent;
      }

      &::placeholder {
        color: $gray;
      }
    }
  }
}