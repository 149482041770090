@import 'styles/mdr';

.jobList {
  @include margin-bottom(20px);
  .list {
    border-radius: 5px;
    border: solid 1px $light-gray;
    @include padding(25px);

    .viewProfile {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .imgSection {
        @include margin-right(10px);
        img {
          border-radius: 50%;
          width: 28px;
          height: 28px;
        }
      }

      .profileName {
        @include font-size(12px);
        color: $branding1;
        font-weight: 500;
      }
    }

    .newMessage {
      @include font-size(14px);
      font-weight: 500;
      color: $branding2-light;
      @include margin-bottom(15px);
      position: relative;

      &.unread:before {
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background:  $branding2-light;
        display: inline-block;
        @include margin-right(10px);
      }

      &.nounread {
        color: $gray-c;
        &:before {
          background:  $gray-c;
        }
      }
    }


    .jobStatus {
      @include font-size(14px);
      font-weight: 500;
      color: $gray-c;
      @include margin-bottom(5px);
      &.pending {
        color: $black;
      }
    }

    p {
      @include font-size(14px);
      line-height: 22px;
      @include margin-bottom(20px);
    }

    .jobBottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .jobHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 20px;
      font-weight: bold;
      letter-spacing: -0.25px;
      @include margin-bottom(5px);

      .jobAmount {
        @include font-size(18px);
        font-weight: 500;
      }
    }
  }
}