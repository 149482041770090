@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);

  &.mt50 {
    @include margin-top(50px);
  }
}

.appPost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $graphik;
  border-bottom: 1px solid $light-gray;

  @include padding-top(20px);
  @include padding-bottom(20px);
  @include margin-bottom(25px);

  .appPostName {
    @include font-size(20px);

    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.56px;
    color: $black;
  }

  .appPostDuration {
    @include font-size(12px);

    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.33px;
    color: $gray;
  }
}

.applicantsAndApplications {
  min-height: 120px;

}

.error {
  color: $red;
}

.noApplicantsFound {
  padding: 20px 35%;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}
