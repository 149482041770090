@import '../../styles/mdr';

.payRedirect {
  font-family: $graphik;
  text-align: center;
  @include padding(60px 15px);
  margin: 0 auto;
  max-width: 750px;

  @include media-breakpoint-down(md) {
    @include padding(30px 0);
    max-width: 100%;
  }


  .imgWrapper {
    position: static;
    @include margin(0 0 25px);
  }

  .heading {
    font-weight: 500;
    @include font-size(28px);
    letter-spacing: -.34px;
    margin-bottom: 20px;
    line-height: 1;
  }

  .desc {
    @include font-size(20px);
    letter-spacing: -.56px;
    margin-bottom: 20px;
    line-height: 26px;
    @include margin-bottom(30px);
  }

  .payInfo {
    @include font-size(12px);
    letter-spacing: -.33px;
    line-height: 18px;
    @include margin-top(25px);
    font-weight: 300;
    @include padding(0 150px);

    @include media-breakpoint-down(sm) {
      @include padding(0);
    }
  }
}