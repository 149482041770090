// src\pages\Login\Login.module.scss

@import '../../styles/mdr';

.authPage {
  position: relative;

  .authPageWrap {
    @include padding(100px 0);

    min-height: 500px;

    .authIntro {
      .authTitleData {
        @include padding(25px);

        position: absolute;
        left: 0;
        top: 25%;
        background-color: $white;
        border-radius: 0.3rem;
        text-align: left;

        .authTitle {
          @include font-size(44px);

          font-family: $graphik;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: -1.22px;
          color: #0f0f0f;
        }

        .authSubTitle {
          @include font-size(16px);

          letter-spacing: -0.44px;
          color: #5a5a5a;
        }
      }
    }

    .authForm {
      .authFormWrap {
        @include media-breakpoint-down(md) {
          max-width: 540px;
          margin: 0 auto;
        }

        .authFormTitle {
          @include font-size(28px);
          @include margin-bottom(25px);

          font-family: $graphik;
          font-weight: bold;
          line-height: 1.21;
          letter-spacing: -0.78px;
          color: #0f0f0f;
        }

        .authLabel {
          @include font-size(16px);
          @include margin-bottom(5px);

          font-family: $graphik;
          font-weight: bold;
          letter-spacing: -0.44px;
          color: $black;
        }

        .authInput {
          @include margin-bottom(30px);

          position: relative;

          .errorInput {
            border-color: $red;
          }
        }

        .authButton {
          @include margin(20px 0 15px);
          @include padding-top(15px);

          .loginNote {
            font-family: $graphik;
            font-size: 14px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
        }

        .note {
          color: #5a5a5a;
        }

        .authSubText {
          @include padding(15px 0);
          @include font-size(16px);

          color: #5a5a5a;
          text-align: center;

          a {
            font-family: $graphik;
            font-weight: bold;
            text-decoration: underline;

            @include margin-left(10px);

            &:visited {
              @extend a;
            }
          }
        }
      }

      .errorWrapper {
        position: relative;

        .error {
          bottom: 0;
        }
      }

      .error {
        color: $red;
        position: absolute;
        bottom: -24px;

        @include font-size(14px);
      }
    }
  }

  .diffSite {
    @include padding(15px 0);
    @include font-size(15px);
    color: #5a5a5a;
    font-weight: 300;
    font-style: italic;
  }

  .forgotPasswordLink {
    @include font-size(14px);

    text-align: right;

    a {
      @include margin-left(10px);

      font-family: $graphik;
      font-weight: bold;
      text-decoration: underline;

      &:visited {
        @extend a;
      }
    }
  }
}
