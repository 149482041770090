@import '../../../../styles/mdr';

.sidebarWrapper {
  @include padding-left(95px);

  @include media-breakpoint-down(md) {
    @include padding-left(30px);
  }

  @include media-breakpoint-down(sm) {
    @include padding-left(0px);
    @include margin-top(30px);
  }

  .boxWrap {
    border-top: 1px solid $gray;
    @include padding(28px 20px);
    @include margin-bottom(50px);

    @include media-breakpoint-down(sm) {
      @include padding(25px 0px);
      @include margin-bottom(0px);
    }

    &:last-child {
      @include margin-bottom(0px);
    }
  }

  .heading {
    letter-spacing: -0.78px;
    line-height: 1;
    @include font-size(28px);
    font-weight: 500;
    @include margin-bottom(10px);
  }

  .subHeading {
    color: $gray;
    font-weight: 300;
  }

  .list {
    @include font-size(18px);
    a {
      display: inline-block;
      font-weight: 500;
      @include margin-top(15px);
      color: $black;

      &:hover {
        color: $branding1;
      }
    }
  }
}
