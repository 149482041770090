// src\components\Form\Checkbox\Checkbox.module.scss

@import '../../../styles/mdr';

$checkXY: 1.2em !default;

.mdrCheck {

  label {
    @include font-size(16px);

    color: $dark-gray;
    cursor: pointer;
    display: inline-block;
    font-family: $graphik;
    font-weight: 500;
    height: $checkXY;
    padding: 0 0 0 1.85em;
    position: relative;

    &::before {
      border: 1px solid #979797;
      display: block;
      height: $checkXY;
      left: 0;
      position: absolute;
      top: 1px;
      content: '';
      transition:
        0.25s background-color ease-in-out,
        0.25s color ease-in-out,
        0.25s border-color ease-in-out;
      width: $checkXY;
    }

    &::after {
      display: block;
      height: $checkXY;
      left: 0;
      position: absolute;
      top: 1px;
      transition:
        0.25s background-color ease-in-out,
        0.25s color ease-in-out,
        0.25s border-color ease-in-out;
      width: $checkXY;
    }

    &::selection {
      user-select: none;
    }
  }

  input[type='checkbox'] {
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;
    z-index: -1;

    + {
      label {
        &::after {
          align-items: center;
          content: "\2713";
          color: $white;
          display: flex;
          justify-content: center;
          text-align: center;
          transform: scale(0);
          transition: 0.25s transform ease-in-out;
        }
      }
    }

    &:checked {
      + {
        label {
          &::before {
            background: $branding1;
            border-color: $branding1;
          }

          &::after {
            transform: scale(1);
          }
        }
      }
    }
  }

  .mdrCheckbox {
    padding: 0;
  }
}
