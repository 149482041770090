@import '../../../../../styles/mdr';

.talentList {
  font-family: $graphik;
  display: flex;
  align-items: center;
  @include padding(12px 35px);
  border-radius: 5px;
  border: solid 1px $light-gray;
  @include margin-bottom(20px);

  @include media-breakpoint-down(md) {
    @include padding(12px 15px);
  }

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  .talentImg {
    width: 80px;

    @include media-breakpoint-down(xs) {
      width: 100%;
      text-align: center;
    }

    img, svg {
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }
  }

  .talentInfo {
    width: calc(100% - 250px);
    @include padding-left(25px);

    @include media-breakpoint-down(xs) {
      width: 100%;
      @include padding-left(0);
      @include margin-top(10px);
      text-align: center;
    }

    .talentTitle {
      @include font-size(20px);
      font-weight: bold;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(xs) {
        justify-content: center
      }

      span {
        @include margin-right(10px);
      }
    }

    .talentSkills {
      @include font-size(12px);
      font-weight: 500;
      line-height: 20px;
      color: $medium-500;
    }
  }


  .talentAvailability {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-down(xs) {
      align-items: center;
    }

    .payRange {
      font-weight: 500;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      text-align: center;
      border-top: 1px solid $xlight-gray;
      @include margin-top(10px);
    }
    > button,
    >div {
      @include margin(5px 0);
    }
  }
}

.error {
  color: red;
}