// src\pages\Search\Search.module.scss

@import '../../styles/mdr';

.searchPage {
  .pageSidebar {
    @include media-breakpoint-up(lg) {
      max-width: 200px;
    }
  }
}
