// src\pages\Home\components\Hero\Hero.module.scss

@import '../../../../styles/mdr';

.heroWrap {
  @include margin-top(25px);

  .heroBanner {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 385px;

    @include media-breakpoint-down(sm) {
      min-height: 300px;
    }

    @include media-breakpoint-down(xs) {
      min-height: 240px;
    }

    .heroBannerImage {
      // @include media-breakpoint-down(md) {
      //   display: none;
      // }

      position: absolute;
      right: 0;
      top: 0;
      width: 840px;
      max-width: 100%;
      box-sizing: border-box;

      @include media-breakpoint-down(sm) {
        height: 100%;
        overflow: hidden;
        border-radius: 0 20px 20px 0;
      }

      img {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;

        @include media-breakpoint-down(sm) {
          height: 100%;
          max-width: none;
          width: auto;
        }
      }
    }

    .heroBubble {
        @include padding(20px);

        display: flex;
        justify-content: center;
        flex-direction: column;
        background-image: url('../../../../assets/svgs/Hero/whitebox.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 740px;
        min-height: 264px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        min-height: 0px;
        border-radius: 20px;
        padding: 15px;
        background: #fff;
      }

      width: 100%;

      .heroBubbleData {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
      }
    }

    .heroTitle {
      // @include font-size(58px);
      @include font-size(46px);
      @include padding-top(15px);

      font-family: $graphik;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -1.62px;
      color: $heading-color;

      @include media-breakpoint-down(sm) {
        @include font-size(30px);
      }
    }

    .heroDesc {
      @include font-size(24px);
      @include padding(15px 0);

      font-family: $opensans;
      letter-spacing: -0.67px;
      color: #5a5a5a;

      @include media-breakpoint-down(sm) {
        @include font-size(20px);
      }
    }

    .heroLink {
      font-family: $graphik;
      font-weight: bold;
      color: $branding1;

      &:hover {
        color: $branding-darken-10;
      }
    }
  }

  .heroBlock {
    @include margin(50px 0 0);

    .heroBlockWrap {
      @include padding-top(25px);

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      border-top: 1px solid #979797;

      .heroBlockLabel {
        @include font-size(14px);
        @include padding(15px 0);

        font-family: $graphik;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .heroBlockTitle {
        @include font-size(46px);
        @include padding-bottom(15px);
        @include padding-right(25px);

        font-family: $graphik;
        font-weight: 500;
        letter-spacing: -1.33px;
        line-height: 1;
        color: $black;
        @include media-breakpoint-down(md) {
          @include padding-right(0px);
        }
      }

      .heroBlockDesc {
        @include font-size(16px);

        font-family: $graphik;
        font-weight: 300;
        line-height: 1.25;
        letter-spacing: -0.44px;
        color: #909090;
        @include media-breakpoint-down(md) {
          @include margin-bottom(25px);}
      }

      .heroBlockIconWrap {
        position: relative;
        text-align: right;

        .heroBlockIcon {
          position: relative;
          z-index: 1;
        }

        .heroBlockIconBg {
          position: absolute;
          bottom: -25px;
          right: 0;
          margin-right: auto;
          z-index: -1px;
        }
      }

      .heroIconCol {
        @include media-breakpoint-up(md) {
          @include padding-right(30px);

          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        @include media-breakpoint-down(md) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.heroBlockOpportunities {
      .heroBlockLabel {
        color: $branding1;
      }
    }

    &.heroBlockCareers {
      .heroBlockLabel {
        color: $branding2;
      }
    }
  }
}
