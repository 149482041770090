@import 'styles/mdr';

.formDivider {
  @include margin(25px 0);

  border-top: 1px dashed #d1d1d1;
  height: 1px;
  width: 100%;
  max-width: 100%;
}

.inputLabel {
  @include font-size(16px);
  @include margin(15px 0);

  font-family: $graphik;
  font-weight: bold;
  letter-spacing: -0.44px;
  color: $black;
}

.cardsWrapper {
  button {
    font-size: 15px;
    font-family: $graphik;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid #c4c4c4;
    border-radius: 3rem;
    color: #5a5a5a;
    transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;
    padding: 6px 15px;
    &:hover, &:active {
      background-color: #e1fff1;
      color: #1c1c1c;
      border: 1px solid #c4c4c4;
    }
  }
}