// src\pages\profile\header\header.module.scss

@import '../../../../styles/mdr';

.profileHeaderContainer {
  .p0 {
    @include padding(0);
  }
}

.profileHeaderWrapper {
  // max-width: 822px;

  // @include padding(0 15px);
  // @include margin(0 auto);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  top: 40px;

  @include media-breakpoint-down(md) {
    @include padding(15px 0);

    top: 0;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  .imgWrapper {
    width: 176px;
    min-height: 170px;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 6px;

    @include media-breakpoint-down(sm) {
     // width: 100%;

      @include margin-bottom(15px);
    }

    img, .profilePic {
      @include border-radius(4px);
      min-width: 170px;
      max-width: 100%;
      height: 170px;
    }

    svg {
      max-width: 100%;

      @include border-radius(4px);
    }
  }

  .userProfile {
    width: calc(100% - 176px);

    @include padding-left(56px);

    @include media-breakpoint-down(md) {
      @include padding-left(30px);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;

      @include padding-left(0);
    }
  }
}

.userProfile {
  color: $white;
  font-family: $graphik;

  @include padding-top(6px);

  .userName {
    @include font-size(36px);

    line-height: 1.2;
    letter-spacing: -1px;
    font-weight: 700;

    @include margin-bottom(12px);
  }

  .userProfileBottom {
    display: flex;
  }

  .userLocation {
    @include font-size(20px);

    letter-spacing: -0.54px;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      @include margin-bottom(12px);
    }
    &:first-child {
      margin-right: 12px;
    }
  }

  .mapIcon {
    color: $gray;

    @include margin-right(12px);
  }

  .buttonWrapper {
    position: absolute;
    right: 15px;
    top: 60px;

    @include media-breakpoint-down(sm) {
      position: static;
    }
  }
}
