// src\pages\JobBoard\Page\Page.module.scss

@import '../../../styles/mdr';

.resultPage {
  .jobDetailsContainer {
    position: relative;
    min-height: 100px;
    @include padding(40px 0 0);
  }

  .resultSidebar {
    @include media-breakpoint-up(lg) {
      max-width: 200px;
    }
  }
  .invited {
    margin-top: -35px;
    margin-bottom: 20px;
  }
}
