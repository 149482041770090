@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);
  text-transform: capitalize;
  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.careersApplied {
  min-height: 120px;
}

.noJobsFound {
  padding: 20px 35%;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}

.error {
  color: $red;
}
