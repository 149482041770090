@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.descriptor {
  font-weight: normal;
  font-size: 14px;
}

.freeCred {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.25px;
  color: #323232;
}

.line {
  border-bottom: solid 1px #d3d3d3;
  height: 1px;
  width: 100%;
  margin-bottom: 46px;
}

.brandingLink {
  @include font-size(18px);
  @include padding(15px 50px);
  border: 1px solid transparent;
  border-radius: 3rem;
  font-family: $graphik;
  font-weight: bold;
  line-height: 1;
  background-color: $branding1;
  color: $white;
  display: block;
  text-align: center;
  margin-bottom: 42px;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active:focus {
    background-color: $branding-darken-10;
    color: $white;
    border-color: transparent;
    background-image: none;
    outline: 0;
    box-shadow: none;
  }
}

:global {
  .btn-grey-ghost {
    border-radius: 3rem;
    font-family: $graphik;
    font-weight: 500;
    line-height: 1;
    border: 1px solid #c4c4c4;
    background-color: transparent;
    color: #5a5a5a !important;
    text-align: center;
    width: 70%;
    display: block;
    margin-bottom: 42px;
    @include padding(15px 50px);
    font-weight: 600;
    &:hover {
      opacity: 0.9;
      color: $white;
    }
  }
}

.credDescription {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.noCertificate {
  @include margin-bottom(40px);
  @include padding(20px 15px 32px);
  border: 1px solid lighten($medium-500, 55%) ;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  .noCertBadge {
    @include margin-bottom(8px);
  }

  .noResumeHeading {
    @include font-size(18px);
    font-weight: 700;
  }

  .noResumeDate {
    @include font-size(14px);
    font-weight: 500;
    letter-spacing: -0.22px;
  }
}