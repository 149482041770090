// src/pages/Register/Register.module.scss

@import '../../../styles/mdr';

.authPage {
  position: relative;

  .authPageWrap {
    @include padding(100px 0 );

    min-height: 500px;

    .authIntro {
      .authTitleData {
        @include padding(25px);

        position: absolute;
        left: 0;
        top: 25%;
        background-color: $white;
        border-radius: 0.3rem;
        text-align: left;

        .authTitle {
          @include font-size(44px);

          font-family: $graphik;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: -1.22px;
          color: #0f0f0f;
        }

        .authSubTitle {
          @include font-size(16px);

          letter-spacing: -0.44px;
          color: #5a5a5a;
        }
      }
    }

    .authForm {
      .authFormWrap {
        @include media-breakpoint-down(md) {
          max-width: 540px;
          margin: 0 auto;
        }

        .authFormTitle {
          @include font-size(28px);
          @include margin-bottom(25px);

          font-family: $graphik;
          font-weight: bold;
          line-height: 1.21;
          letter-spacing: -0.78px;
          color: #0f0f0f;
        }

        .authLabel {
          @include font-size(16px);
          @include margin-bottom(5px);

          font-family: $graphik;
          font-weight: bold;
          letter-spacing: -0.44px;
          color: $black;
        }

        .authInput {
          @include margin-bottom(30px);

          position: relative;

          .required {
            color: $red;
          }

          .errorInput {
            border-color: $red;
          }

          .requiredFieldsNote {
            @include font-size(12px);

            position: absolute;
            right: 0;
            top: 5px;
            margin-bottom: 5px;
            font-weight: 700;

            span {
              color: $red;
            }
          }
        }

        .authButton {
          @include margin(15px 0);
          @include padding-top(15px);
        }

        .authSubText {
          @include padding(15px 0);
          @include font-size(16px);

          color: #5a5a5a;
          text-align: center;

          a {
            font-family: $graphik;
            font-weight: bold;
            text-decoration: underline;

            @include margin-left(10px);

            &:visited {
              @extend a;
            }
          }
        }
      }

      .error {
        color: $red;
        position: absolute;
        bottom: -22px;

        @include font-size(14px);
      }
    }
  }

  .diffSite {
    @include padding(15px 0);
    @include font-size(15px);
    color: #5a5a5a;
    font-weight: 300;
    font-style: italic;
  }
}

.signUpTerms {
  @include font-size(15px);

  text-align: center;
  font-weight: 600;
}
