@import '../../styles/mdr';

.supportPage {
  min-height: 500px;
  @include padding(50px 15px 0);

  .heading {
    @include font-size(30px);
    display:none;
  }
}