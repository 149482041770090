@import '../../../../styles/mdr';

.dashMenu {
    ul {

        list-style: none;
        padding: 0;
        margin: 0;
    }

    >ul {
        margin-left: -10px;

        >li {
            margin-bottom: 30px;

            >span {
                display: block;
                @include font-size(12px);
                padding: 0 18px;
                font-weight: 300;
                line-height: 15px;
                letter-spacing: 1.14px;
                color: $medium-gray;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            li {
                @include font-size(14px);
                font-weight: 500;
                letter-spacing: -0.22px;
                margin-bottom: 8px;
                max-width: 200px;

                a {
                    color: $medium-gray;
                    border-radius: 3rem;
                    display: block;
                    line-height: 36px;
                    padding: 0 18px;
                    position: relative;

                    .count {
                      position: absolute;
                      @include font-size(12px);
                      font-weight: 500;
                      letter-spacing: 0.75px;
                      background: $branding2;
                      color: $white;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      right: 15px;
                      top: 8px;
                      height: 20px;
                      min-width: 19px;
                      border-radius: 2px;
                      -webkit-border-radius: 2px;
                      -moz-border-radius: 2px;
                      -ms-border-radius: 2px;
                      -o-border-radius: 2px;
                      padding: 4px 3px 3px 4px;
                    }

                    &.active,
                    &:hover {
                        background-color: $branding1-1;
                    }
                }
            }
        }

    }
}