@import '../../styles/mdr';

.dashboardWrapper {
    .dashboardInner {
        padding: 0;
        font-family: 'Graphik';
        @include margin-top(25px);

        h1 {
            @include font-size(24px);
            font-weight: bold;
            letter-spacing: -0.67px;
            color: $dark-gray;
        }
    }
}

.userName {
    @include font-size(40px);
    font-weight: 300;
    line-height: 1;
    color: $dark-gray;
}

.emailId {
    @include font-size(12px);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: $gray;
    margin-top: 8px;
}

.dashboardContent {
    @include margin-top(40px);
}