@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.profileInfo {
  @include font-size(16px);

  line-height: 20px;
  font-weight: 500;
  color: $medium-gray;
  padding-bottom: 6px;
  border-bottom: 1px solid $gray;

  @include margin-top(30px);
  @include margin-bottom(22px);

  span {
    display: block;
    font-weight: 300;

    @include font-size(13px);
  }
}

.authLabel {
  @include font-size(16px);

  font-weight: bold;
  letter-spacing: -0.44px;
  margin-bottom: 8px;
}

.authInput {
  @include margin-bottom(25px);

  position: relative;

  .authText {
    line-height: 1;
    font-family: "Open Sans", sans-serif;

    @include font-size(12px);

    color: $gray;
    font-style: italic;
  }
}

.authButton {
  @include margin-top(50px);
}

.textRight {
  text-align: right;
}

.error {
  @include font-size(14px);

  position: absolute;
  color: $red;
  margin-top: 5px;
}
