@import 'styles/mdr';

$modal-width: 840px;

.offerWrapper {
  font-family: $graphik;
  max-width: $modal-width;
  @include margin(40px auto 0);
  .authLabel {
    @include font-size(16px);
    @include margin-bottom(15px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.44px;
    color: $black;

    span {
      font-weight: normal
    }
  }

  .error {
    color: $red;

    @include font-size(14px);
  }

  .information {
    font-size: 0.875rem;
    font-family: "Open Sans", sans-serif;
    line-height: 1.57;
    letter-spacing: -0.39px;
    color: #909090;
  }

  span.requiredField {
    color: $red;
    font-weight: bold !important;
  }

  .attachmentsListing {
    display: flex;
    margin-bottom: 20px;
    .attachmentRemove {
      cursor: pointer;
      margin-left: 20px;
      color: $red;
    }
    .attachmentIcon {
      margin-right: 10px;
    }
  }

  .requiredFieldsNote {
    @include font-size(12px);

    position: absolute;
    right: 0;
    top: 20px;
    margin-bottom: 5px;
    font-weight: 700;

    span {
      color: $red;
    }
  }

  .authInput {
    @include margin-bottom(30px);
    position: relative;

    :global {

      input{
        margin: 0;
        min-height: 50px;
        border-radius: 0;
        border: 1px solid $gray-c;
        @include padding(0 12px);
        outline: 0;
        width: 100%;

        &:hover {
          outline: 0;
          border-color: $branding1;
        }

        &:focus,
        &:active {

        outline: 0;
          // border-color: lighten($branding1, 0.5%);
          border-color: $branding-lighten-05;
          box-shadow: 0 0 0 0 transparent;
        }

        &::placeholder {
          color: $gray;
        }
      }
    }

    textarea {
      height: 250px;
      resize: none;

      &.shortText {
        height: 100px
      }
    }
  }
  .pageHeading {
    @include font-size(46px);
    font-weight: 900;
    letter-spacing: -0.8px;
  }

  .iconButton {

    @include margin-bottom(30px);
    button {
      position: relative;
      &:before {
        content: '+';
        margin-right: 10px;
        color: $blue;
      }

      &:hover {

        &:before {
          color: $white;
        }
      }
    }
  }

  .jobTitle {
    @include font-size(24px);
    line-height: 1;
    color: $medium-500;
    @include margin(0 0 40px);
  }

  .userDetailOuter {
    display: flex;
    align-items: center;
    @include margin(0 0 30px);

    .userName {
      @include font-size(12px);
      text-transform: capitalize;
      font-weight: 500;
      color: $branding1;
    }
  }

  .userAvatar {
    @include margin-right(15px);
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
}


.closeBtn {
  @include font-size(12px);
  cursor: pointer;
  font-weight: 500;
  color: darken($light-gray, 15%);
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  align-items: center;

  span {
    @include margin-left(2px);
  }
}
.conterOffers {
  background: $branding3-1;
  @include padding(10px 20px);
  // max-width: 380px;
  width: 100%;
  position: relative;
  top: -15px;
  @include margin-bottom(20px);

  @include media-breakpoint-down(md) {
    top: 0px;
  }

  .heading {
    @include font-size(10px);
    text-transform: uppercase;
    letter-spacing: 1.44px;
    color: $dark-yellow;
    font-weight: 700;
  }

  .desc {
    @include font-size(12px);
    letter-spacing: -0.33px;
    font-weight: 500;
  }
}

.authLabelInfo {
  @include font-size(12px);
  @include margin-top(-10px);
  @include margin-bottom(10px);
}

.fileInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-style: italic;
  font-family: $opensans;
  line-height: 1.8;
  letter-spacing: -0.33px;
  @include font-size(12px);
  color: $gray;
  @include margin-top(10px);
  @include margin-bottom(60px);
}

.applicationSentModalWrap {

  :global {
  .modal-content {
    @include padding(50px 60px 40px);
    font-family: $graphik;
    position: relative;

    @include media-breakpoint-down(sm) {
       @include padding(40px 25px 40px);
    }

    .modal-body {
      padding: 0;

    }
  }
}

  .applicationReceived {

    text-align: center;
    color: green;
    font-weight: bold;

    .planeIconContainer {
      font-weight: bold;
      margin-bottom: 25px;

      .planeIcon {
        font-weight: bold;
      }
    }

    .smallText {
      @include font-size(12px);

      color: $black;
      font-weight: 600;
    }

    .bigText {
      @include font-size(26px);

      font-weight: 900;
      color: $black;
    }
  }
}

.infoHover {
  display: inline-block;
  @include border-radius(50%);
  background: $light-gray;
  color: $gray;
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-weight: 500;
  @include margin-left(5px);
  @include font-size(10px);
  text-align: center;
  font-style: italic;
  font-family: $opensans;
}

.shortMess {
  display: inline-block;
  @include margin-left(5px);
  color: $branding1;
  @include font-size(14px);
}