// src\pages\Home\components\Listing\Listing.module.scss

@import '../../../../styles/mdr.scss';

.listingTable {
  @include margin(100px 0);

  .listingHeader {
    .listingTitle {
      @include font-size(52px);

      font-family: $graphik;
      font-weight: 300;
      color: $dark-gray;
      letter-spacing: -1.44px;
      line-height: 1;
    }

    .listingDesc {
      @include font-size(16px);

      @include media-breakpoint-down(md) {
        @include padding(15px 0);
      }

      .listingMore {
        @include font-size(18px);
        @include padding(10px 0 0 0);

        font-family: $graphik;
        font-weight: 500;
      }
    }
  }

  .listingLabel {
    @include margin(25px 0 0 0);

    position: relative;
    @include media-breakpoint-down(xs) {
      text-align: center;
    }

    span {
      @include font-size(24px);

      @include media-breakpoint-up(lg) {
        @include padding(15px 15px 15px 0);
      }

      display: inline-block;
      background-color: $white;
      color: #000;
      font-family: $graphik;
      font-weight: 500;
      letter-spacing: -0.67px;
      position: relative;
      z-index: 1;
    }

    &::after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 97%;
        height: 1px;
        background-color: #d4d4d4;
        z-index: 0;
      }
    }
  }

  .listingViewAll {
    @include padding(50px 0);

    text-align: center;
  }
}
