// src\styles\base\_anchor.scss

html {
  body {
    a {
      color: $branding1;
      transition: 0.2s color ease-in-out;
      text-decoration: none;

      &:hover {
        color: $branding-darken-10;
        text-decoration: none;
      }

      &:visited {
        color: $branding1;
        transition: 0.2s color ease-in-out;
        text-decoration: none;
      }
    }
  }
}
