@import '../../../../styles/mdr';

.mainHeading {
  @include font-size(72px);
  @include margin-bottom(20px);
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 1;

  span {
    display: inline-block;
    position: relative;

    &::before {
      content: '';
      height: 59px;
      width: 369px;
      background: $branding1-1;
      display: block;
      position: absolute;
      z-index: -1;
      left: 2px;
      top: 6px;
      @include media-breakpoint-down(sm) {
        width: 250px;
        height: 40px;
      }
    }
  }
}

p {
  @include font-size(18px);
  letter-spacing: -0.5px;
  line-height: 1.5;
  font-family: $opensans;
  color: $medium-500;

  strong {
    color: $black;
    font-weight: 600;
  }
}

.content {
  @include margin-top(40px);
  @include padding-top(95px);
  @include padding-right(55px);
  position: relative;

  @include media-breakpoint-down(sm) {
    @include margin-top(50px);
    @include padding-right(0px);
    @include padding-top(30px);
  }

  &::before {
    content: '';
    width: 307px;
    height: 1px;
    background: $gray;
    position: absolute;
    top: 0;
    left: 0;
  }
  p {
    @include font-size(36px);
    color: $black;
    line-height: 1.2;
    letter-spacing: -1px;
    @include margin-bottom(60px);
  }
}