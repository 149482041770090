// src\pages\Careers\components\Search\Filter\Filter.module.scss

@import '../../../../../styles/mdr';

.searchResults {
  min-height: 100px;
  position: relative;
}

.noJobsFound {
  padding: 20px 40%;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}

.listingViewAll {
  @include margin-top(50px);

  text-align: center;
}
