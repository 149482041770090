@import '../../../styles/mdr';

.resultOptions {
  @include margin-bottom(32px);
  @include margin-top(25px);

  button + button {
    @include margin-left(10px);
  }

  @include media-breakpoint-down(sm) {
    button {
      @include margin-bottom(10px);
    }
  }

  button + a {
    @include margin-left(10px);
  }

  .unread {
    @include font-size(8px);
    letter-spacing: 0.75px;
    background: $branding2;
    color: $white;
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    vertical-align: middle;
    padding-top: 4px;
    padding-left: 1px;
  }
}

.applicantsList {
  border: 1px solid $light-gray;
  border-radius: 5px;

  @include padding(14px 18px);


  &.appDisabled{
    opacity: 0.4;
    pointer-events: none;
  }

  &.hired {
    opacity: 0.4;
    pointer-events: none;
  }
}

.applicantsListWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: $graphik;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    position: relative;
  }

  .appStatus {
    position: absolute;
    top: 15px;
    right: 15px;

    button {
      @include font-size(12px);
    }
  }

  .imgWrapper {
    @include media-breakpoint-down(sm) {
      @include margin-top(0);

      order: 2;
    }

    img {
      width: 80px;
      height: auto;
      border-radius: 50%;
    }
  }

  .offer {
    margin-left: auto;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      @include margin-bottom(10px);
    }

    .offerAmount {
      @include font-size(18px);
      line-height: 26px;
      font-weight: 500;
    }

    .offerLabel {
      @include font-size(12px);
      line-height: 26px;
      font-weight: 500;
    }
  }

  .appDetails {
    flex: 0 0 calc(100% - 250px);
    max-width: calc(100% - 250px);

    @include padding-left(15px);

    font-family: $graphik;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;

      @include padding-left(0);
      @include margin-top(10px);
      text-align: center;

      order: 3;
    }

    .appName {
      @include font-size(18px);
      @include margin-bottom(4px);

      line-height: 26px;
      letter-spacing: -0.28px;
      font-weight: 700;

      a {
        color: $branding-darken-10;
      }
    }

    .appContact {
      @include font-size(14px);

      font-weight: 500;

      span {
        border-right: 1px solid $gray;
        padding-right: 8px;
        margin-right: 8px;

        @include media-breakpoint-down(sm) {
          display: block;
          border: none;
          margin: 0;
          padding: 0;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;
          border: none;
        }
      }
    }

    .appAbout {
      @include font-size(14px);
      line-height: 22px;
      letter-spacing: -0.19px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $medium-gray;

      @include margin-top(0px);

      > div {
        @include margin-right(15px);

        a {
          display: flex;
          align-items: flex-end;

          svg {
            @include margin-right(5px);
            @include font-size(14px);
          }

          span {
            line-height: 1;
            display: inline-block;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}
