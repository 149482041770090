@import '../../../styles/mdr';

.editorWrapper {
  border: 1px solid #f1f1f1;
}

.toolbar {
  border: none;
  border-bottom: 1px solid #f1f1f1;
}

.textEditor {
  padding: 0 10px 10px 10px;
}

.activeJob {

  font-family: $graphik;

  @include media-breakpoint-down(lg) {
    @include padding-left(15px);
    @include padding-right(15px);
  }

  .jobLeftSec {
    width: calc(100% - 300px);
    @include padding-right(60px);

    @include media-breakpoint-down(md) {
      width: 100%;
      @include padding-right(0px);
      @include margin-bottom(25px);
    }
  }

  .jobDetailTop {
    @include margin-bottom(45px);

    .jobLabel {
      @include font-size(12px);
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $gray;
      font-weight: 500;
      line-height: 1;
      border-top: 1px solid $light-gray;
      @include padding-top(12px);
      @include margin-bottom(12px);
    }

    .jobValue {
      font-weight: 500;
      @include font-size(16px);

      @include media-breakpoint-down(xs) {

        @include margin-bottom(12px);
      }
    }
  }

  .chatSection {
    .heading {

      @include font-size(28px);
      letter-spacing: -0.44px;
      font-weight: 500;
      @include margin-bottom(25px);
    }
  }

  .headWrapper {
    @include margin-bottom(50px);

    .mainHeading {
      @include font-size(28px);
      @include margin-bottom(20px);
      line-height: 1;
      font-weight: 500;
    }

    .subHeading {

      @include font-size(20px);
      line-height: 26px;
    }
  }

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include margin-top(10px);
    @include padding-bottom(15px);
    border-bottom: 1px solid $light-gray;
    @include margin-bottom(40px);

    @include media-breakpoint-down(sm) {
      @include margin-top(20px);
    }

    .topBarLeft {
      @include font-size(20px);
      line-height: 26px;
      letter-spacing: -0.26px;
    }

    .newMessage {
      @include font-size(14px);
      line-height: 1;
      font-weight: 500;
      color: $branding2-light;

      position: relative;

      &:before {
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background:  $branding2-light;
        position: absolute;
        left: -24px;
        top: 1px;
      }
    }
  }

  .textAreaOuter {
    @include padding(0 65px);
    @include margin-bottom(26px);

    @include media-breakpoint-down(sm) {
      @include padding(0);
    }

    textarea {
      resize: none;
    }
  }

  .helpSection {
    @include padding(0 65px);
    @include margin-bottom(26px);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include media-breakpoint-down(sm) {
      @include padding(0);
    }

    .imgSection {
      @include margin-right(10px);
    }

    .helpDetail {
      line-height: 1.2;
      @include font-size(14px);
      line-height: 18px;
      font-weight: 500;

      .bottomDetail {
        color: $branding1;
      }
    }
  }

  .smallNote {
    @include margin(34px 65px);
    @include padding(7px 20px);
    border-radius: 1px;
    background-color: lighten($light-pink, 3%);
    @include font-size(14px);
    letter-spacing: -0.22px;
    font-weight: 500;
    line-height: 26px;

    @include media-breakpoint-down(sm) {
      @include margin(34px 0);
    }

  }
  .chatBox {
    @include margin-top(42px);

    .btnOuter {
      @include padding(0 65px);
      @include margin-bottom(20px);
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        @include padding(0);
      }
    }
    .list{
      @include margin-bottom(20px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      &.listClient {
          justify-content: flex-start;

          .img {
            order: 1;
            text-align: left;
          }
          .messageInfo {
            order: 2;
            border: 1px solid $branding1;
            background: $branding-1_1_light;
          }
      }

      &.newChat {
        .messageInfo {
          border: 1px solid $branding2-light;
          position: relative;

          &:after {
            content: '';
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background:  $branding2-light;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }

      .img {
          width: 65px;
          order: 2;
          text-align: right;

          img {
            max-width: 50px;
            height: auto;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
      }
      .messageInfo {
        flex: 0 0 calc(100% - 130px);
        max-width: calc(100% - 130px);
        font-family: $graphik;
        @include padding(20px);
        border: 1px solid $light-gray;

        @include media-breakpoint-down(sm) {
          @include padding(10px);
          flex: 0 0 calc(100% - 65px);
          max-width: calc(100% - 65px);
        }

        .user {
          @include font-size(12px);
          line-height: 16px;
          letter-spacing: -0.19px;
          color: $branding1;
          font-weight: 500;
        }

        .time {
          @include font-size(12px);
          line-height: 16px;
          letter-spacing: -0.19px;
          color: $gray;
          font-weight: 500;
        }

        .MessageHeading {
          @include font-size(16px);
          line-height: 26px;
          letter-spacing: -0.56px;
          color: $black;
          @include margin-bottom(15px);

          a {
            color: $black;
          }
        }
      }
    }
  }
}

.jobRightSec {
  width: 300px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .dueDays {
    @include padding-bottom(16px);
    border-bottom: 1px solid $light-gray;
    @include padding-left(5px);
    @include margin-bottom(14px);

    .secLabel {
      line-height: 1;
      @include margin-bottom(8px);
    }

    .secValue {
      @include font-size(30px);
      font-weight: 500;
    }

    .jobDate {
      @include margin-top(3px);
    }

  }

  .shotDesc {
    @include margin(30px 0);
    @include font-size(14px);
    line-height: 22px;
    letter-spacing: -0.22px;
  }

  .schBreakdown {
    .schListOuter {

      .schListing {

        .schTiming {
          font-weight: 500;
          @include margin-bottom(3px);
        }

        &.active {
          .schTiming {
            &::after {
              content: '';
              position: absolute;
              left: 8px;
              top: 8px;
              background: $branding1;
              border-radius: 50%;
              height: 9px;
              width: 9px;
              z-index: 1;
            }
          }
        }
      }

      .schListing {
        @include padding-bottom(30px);
        @include padding-left(40px);
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 12px;
          top: 12px;
          background: $gray-c;
          height: 100%;
          width: 1px;
        }

        &::after {
          content: '';
          position: absolute;
          left: 5px;
          top: 5px;
          background: $white;
          border-radius: 50%;
          height: 15px;
          width: 15px;
          border: 1px solid $gray-c;
        }

        &:last-child {
          @include padding-bottom(0);

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .profileSec {
    @include margin-bottom(50px);

    .secLabel {
      line-height: 1;
      @include font-size(12px);
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $gray;
      font-weight: 500;
      @include margin-bottom(12px);
    }
    .secValue {
      font-weight: 500;
    }
  }
}

.btnOuterMain {

  button {
    @include margin-bottom(18px);
  }
}

.mainContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.error {
  color: red;
}

.extendScopeModal {
  .addButton {
    margin-bottom: 20px;
  }
  .authLabel {
    @include font-size(16px);

    letter-spacing: -0.44px;
    margin-bottom: 14px;

    span {
      @include font-size(12px);
    }
  }

  .modalNoti {
    display: flex;
    @include margin-bottom(25px);

    span:first-child {
      @include margin-right(15px);
    }

    span {
      @include font-size(12px);
      font-weight: 18px;
    }
  }

  .authInput {
    @include margin-bottom(25px);

    position: relative;

    textarea {
      resize: none;
      height: 140px;
    }
  }
  .closeBtn {
    @include font-size(12px);
    cursor: pointer;
    font-weight: 500;
    color: darken($light-gray, 15%);
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    align-items: center;

    span {
      @include margin-left(2px);
    }
  }
  :global {
    .modal-content {
      @include padding(40px 60px 40px);
      font-family: $graphik;
      position: relative;

      @include media-breakpoint-down(sm) {
         @include padding(40px 25px 40px);
      }
    }
    .modal-header {
      border: none;
      padding: 0;

      .h4 {
        @include font-size(24px);
        font-weight: bold;
        letter-spacing: -.67px;
        @include margin-bottom(30px);
      }
    }

    .modal-footer {
      padding: 0;
      border: none;
    }

    .modal-body {
      padding: 0;

    }
  }
}