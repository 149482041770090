// src\pages\JobBoard\Page\components\Content\Content.module.scss

@import '../../../../../styles/mdr';

.resultContent {
  .resultDescription {
    @include margin(35px 0 0 0);

    font-family: $opensans;
    line-height: 1.5;
    letter-spacing: -0.44px;
    color: #909090;
  }

  .resultAbout {
    @include font-size(16px);

    font-family: $opensans;
    line-height: 1.5;
    letter-spacing: -0.44px;
    color: #909090;
    position: relative;

    .desc {
      @include font-size(16px);
      font-family: $opensans;
      line-height: 1.5;
      letter-spacing: -0.44px;
      color: $gray;
      position: relative;
      pre {
        white-space: pre-wrap;
        overflow: unset;
        font-family: $opensans;
        line-height: 1.5;
        letter-spacing: -0.44px;
        position: relative;
        color: $black;
      }
      @include padding-right(80px);
      @include margin-bottom(45px);
      @include margin-top(10px);
      @include media-breakpoint-down(sm) {
        @include padding-right(0);
      }
    }

    button {
      position: absolute;
      right: 5rem;
      top: -3px;
      @include media-breakpoint-down(sm) {
        right: 0;
      }
      &.shareButton {
        position: static;
        margin-right: 10px;
      }
    }

    .resultAboutLabel {
      @include font-size(22px);
      @include padding(0 0 10px);

      font-family: $graphik;
      font-weight: 500;
      line-height: 1.18;
      letter-spacing: -0.61px;
      color: $dark-gray;
    }

    .freeCred {
      font-size: 1rem;
      margin-top: 5px;
    }

    .credentialWrap {
      display: flex;
      border: 1px solid #f2daff;
      border-radius: 8px;
      @include padding(20px);
      @include margin-right(80px);

      .credentialBadge {
        flex: 0 0 65px;
        max-width: 65px;
        .credentialBadgeSvg {
          max-width: 50px;
        }
      }

      .credentialData {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 65px);
        max-width: calc(100% - 65px);

        .credentialName {
          @include font-size(18px);
          color: $black;
          font-weight:bold;
        }

        .credentialLink {
          @include font-size(15px);
        }
      }
    }
  }
}
