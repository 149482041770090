@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;
  text-transform: capitalize;

  @include margin-bottom(30px);
}

.invites {
  min-height: 120px;
}

.noJobsFound {
  padding: 20px 35%;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}

.error {
  color: $red;
  margin: auto;
}
