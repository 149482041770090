// src\pages\Careers\components\Sidebar\Sidebar.module.scss

@import '../../../../styles/mdr';

.sidebarData {
  .sidebarDescription {
    @include font-size(16px);
    @include padding-bottom(30px);

    color: #909090;
    font-family: $graphik;
    font-weight: 300;
    letter-spacing: -0.44px;
    line-height: 1.25;
  }

  .sidebarLabel {
    @include font-size(12px);
    @include padding(15px 0 );

    border-top: 1px solid #e3e3e3;
    color: #8d8d8d;
    font-family: $graphik;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    word-wrap: break-word;
  }
}
:global {
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    @include media-breakpoint-down(md) {
      max-height: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
    .accordion-content {
      @include margin-bottom(25px);
      > div {
        >div {
          @include margin-bottom(8px);
        }
      }
    }
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    @include media-breakpoint-down(md) {
      transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
      height: auto;
      max-height: 9999px;
    }
  }

  .accordion-title {

    @include font-size(12px);
    @include padding(15px 0 );
    position: relative;
    border-top: 1px solid #e3e3e3;
    color: #8d8d8d;
    font-family: $graphik;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      top: 20px;
      border-top: 5px solid currentColor;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0;
      @include media-breakpoint-down(md) {
        border-top: 0;
        border-bottom: 5px solid;
      }
    }

    &.open {
      &::after {
        content: "";
        border-top: 0;
        border-bottom: 5px solid;
        @include media-breakpoint-down(md) {
          border-top: 5px solid currentColor;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 0;
        }
      }
    }
  }

}